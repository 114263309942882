import * as React from "react";
import styled from "styled-components";

import * as DOMPurify from "dompurify";

import palette from "@telus-uds/palette-allium/build/web/palette";

const {
  color: { greyAthens, greyMystic, greyThunder },
  fontSize: { size12, size16, size20, size24, size28, size32 },
  letterSpacing: { condensed },
} = palette as {
  color: { greyAthens: string; greyMystic: string; greyThunder: string };
  fontSize: {
    size12: string;
    size16: string;
    size20: string;
    size24: string;
    size28: string;
    size32: string;
  };
  letterSpacing: { condensed: string };
};

const HtmlContentStyle = styled.div`
  dl {
    > div {
      > dt,
      > dd {
        display: inline;
      }
      > dt {
        font-weight: bold;
        ::after {
          content: ": ";
        }
      }
    }
  }
  p,
  section {
    margin-bottom: 1ex;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: ${greyThunder};
    font-weight: 700;
    letter-spacing: ${condensed};
    margin-bottom: 1ex;
  }
  h1 {
    font-size: ${size32};
  }
  h2 {
    font-size: ${size28};
  }
  h3 {
    font-size: ${size24};
  }
  h4 {
    font-size: ${size20};
  }
  h5 {
    font-size: ${size16};
  }
  h6 {
    font-size: ${size12};
  }
  table {
    border: 1px solid ${greyMystic};
    width: 100%;
    max-width: 100%;
    margin-bottom: 1ex;
    tr > th,
    tr:nth-of-type(odd) {
      background-color: ${greyAthens};
    }
    tr > th,
    tr > td {
      border: 1px solid ${greyMystic};
      padding: 1ex;
      word-break: break-word;
    }
  }
  b,
  strong {
    font-weight: bold;
  }
  em,
  i {
    font-style: italic;
  }
  u {
    text-decoration: underline;
  }
  ul {
    list-style-type: disc;
  }
  ol {
    list-style-type: decimal;
  }
`;

export declare interface HtmlContentProps {
  html: string;
}

export const HtmlContent: React.FC<HtmlContentProps> = ({ html }) => {
  return (
    <HtmlContentStyle
      data-testid="html-content"
      dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(html) }}
    ></HtmlContentStyle>
  );
};
