import * as React from "react";
import styled, { ThemeProvider } from "styled-components";

import Checkbox from "@tds/core-checkbox";
import sherlockTheme from "../../../style/sherlock-theme";

export declare interface ServiceCheckboxProps {
  acronym: string;
  label: string;
  name: string;
  value: string | number | boolean;
  error?: string;
  feedback?: "error";
  color?: string;
  id?: string;
  disabled?: boolean;
}

const Label = styled.span`
  display: inline;
  padding: 0.2em 0.6em 0.3em;
  font-size: 75%;
  font-weight: bold;
  line-height: 1;
  color: ${(props) => props.theme.colors.labelColor};
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25em;
`;

const LabelWrapper = styled.span`
  float: left;
  margin-top: 0.125rem;
`;

const LabelAcronym = styled(Label)`
  background-color: ${(props) =>
    props.color ? props.color : props.theme.colors.colorTelusGreen};
  color: ${(props) => props.theme.colors.tdsColorText};
  margin-right: 0.5em;
  width: 4.5em;
  float: left;
  margin-top: 0.18em;
  line-height: 1.14em;
  letter-spacing: 0;
`;

const LabelText = styled.span`
  color: ${(props) => props.theme.colors.tdsColorText};
`;

const ServiceLabel = ({
  color,
  acronym,
  label,
}: {
  color: string;
  acronym: string;
  label: string;
}) => (
  <LabelWrapper>
    <LabelAcronym color={color}>{acronym}</LabelAcronym>
    <LabelText>{label}</LabelText>
  </LabelWrapper>
);

export const ServiceCheckbox: React.FC<ServiceCheckboxProps> = ({
  acronym,
  label,
  color,
  ...rest
}) => (
  <ThemeProvider theme={sherlockTheme}>
    <Checkbox
      label={<ServiceLabel color={color} acronym={acronym} label={label} />}
      {...rest}
    />
  </ThemeProvider>
);
