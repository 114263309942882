import React from "react";
import { useTranslation } from "react-i18next";

import styled from "styled-components";
import { colorGreyShark } from "@tds/core-colours";
import { padds, devices } from "../../../style/global-style";

import leaves from "./palmLeaves.png";
import logo from "./logo-small-white.png";

const FooterStyle = styled.div`
  width: 100%;
  padding: 0;
  font-size: 0.8rem;

  .footerContainer {
    width: 100%;
    color: white;
    background: ${colorGreyShark};
    display: flex;
    flex-direction: column;
    padding: 2rem;
    @media ${devices.tablet} {
      display: grid;
      grid-template-columns: repeat(5, 1fr);
      grid-template-rows: 1fr;
      grid-column-gap: 3rem;
      grid-row-gap: 0px;
      padding-top: 3rem;
      padding-bottom: 3rem;
    }

    #footerCol-1 {
      margin-bottom: 1rem;
      @media ${devices.tablet} {
        grid-area: 1 / 1 / 3 / 4;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;
        margin-bottom: 0;
        
      }
    }

    #footerCol-2 {
      margin-bottom: 2rem;
      @media ${devices.tablet} {
        grid-area: 1 / 4 / 2 / 5;
        margin-bottom: 0;
      }
    }
    #footerCol-3 {
      margin-bottom: 2rem;
      @media ${devices.tablet} {
        grid-area: 1 / 5 / 2 / 6;
        margin-bottom: 0;
      }
    }

    @media ${devices.tablet} {
      padding-left: ${padds.LeftRightTablet};
      padding-right: ${padds.LeftRightTablet};
    }

    @media ${devices.laptop} {
      padding-left: ${padds.LeftRight};
      padding-right: ${padds.LeftRight};
    }

    .logo {
      width: 106px;
      height: 20px;
    }

    .footerTitle {
      font-weight: bold;
    }
  }

  .footerDecoration {
    float: right;
    background-color: white;
    background-image: url(${leaves});
    background-position: right bottom;
    background-repeat: no-repeat;
    width: 100%;
    min-height: 200px;
    background-size: 80%;
    @media ${devices.tablet} {
      min-height: 250px;
      background-size: 50%;
    }
    @media ${devices.laptop} {
      min-height: 250px;
      background-size: 35%;
    }
  }
`;

export declare interface FooterProps {
  supportHotline: string;
  version: string;
}

export const Footer: React.FC<FooterProps> = (props) => {
  const { t } = useTranslation();

  const currentYear = new Date().getFullYear();

  return (
    <FooterStyle>
      <div className="footerDecoration" />
      <div className="footerContainer">
        <div id="footerCol-1">
          <img id="footerCol-1" className="logo" src={logo} alt="" />
          <div>©{currentYear} TELUS Communications Inc.</div>
        </div>
        <div id="footerCol-2">
          <div className="footerTitle">{t("Support")}</div>
          <div>
            {t("SupportContact_msg", { context: "unit" })} <br />
            {props.supportHotline}
          </div>
        </div>
        <div id="footerCol-3">
          <div className="footerTitle">{t("About")}</div>
          <div>
            {t("Version")} {props.version}
            <br />
            Copyright {currentYear}.
            <br /> {t("All rights reserved.")}
          </div>
        </div>
      </div>
    </FooterStyle>
  );
};
