import { createGlobalStyle } from "styled-components";

const SherlockGlobalStyle = createGlobalStyle`
  b,
  strong {
    font-weight: bold;
  }
  i,
  em {
    font-style: italic;
  }
  code {
    font-family: monospace;
  }
`;

export const padds = {
  p4: `0.25rem`,
  p8: `0.5rem`,
  p12: `0.75rem`,
  p16: `1rem`,
  p20: `1.25rem`,
  p24: `1.5rem`,
  p32: `2rem`,
  p40: `2.5rem`,
  p48: `3rem`,
  p64: `4rem`,
  LeftRight: `2rem`,
  LeftRightTablet: `1.5rem`,
  LeftRightMobile: `0.4rem`,
  TopBottom: `2rem`,
  TopBottomTablet: `1.5rem`,
  TopBottomMobile: `0.5rem`,
};

export const viewportSize = {
  mobileS: "320px",
  mobileM: "375px",
  mobileL: "414px",
  tablet: "768px",
  laptop: "1024px",
  laptopL: "1440px",
  desktop: "2560px",
};

export const devices = {
  mobileS: `(min-width: ${viewportSize.mobileS})`,
  mobileM: `(min-width: ${viewportSize.mobileM})`,
  mobileL: `(min-width: ${viewportSize.mobileL})`,
  tablet: `(min-width: ${viewportSize.tablet})`,
  laptop: `(min-width: ${viewportSize.laptop})`,
  laptopL: `(min-width: ${viewportSize.laptopL})`,
  desktop: `(min-width: ${viewportSize.desktop})`,
  desktopL: `(min-width: ${viewportSize.desktop})`,
};

export default SherlockGlobalStyle;
