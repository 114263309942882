import React, { useContext } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import {
  Autocomplete,
  Link,
  Responsive,
  StackView,
} from "@telus-uds/components-web";

import palette from "@telus-uds/palette-allium/build/web/palette";

import { SessionContext } from "../../../contexts";

import { DropdownMenu } from "../DropdownMenu/DropdownMenu";
import { NavBarNotificationWidget } from "../../dashboard-widgets/notification-widget/NavBarNotificationWidget";

import { padds, devices } from "../../../style/global-style";
import { CustomerInfo } from "../../../datamodel/customer";
import { MenuItem } from "../../../datamodel/navigation";

const {
  color: { purpleTelus },
} = palette as {
  color: { purpleTelus: string };
};

const TopbarStyle = styled.div`
  color: ${(props) => props.color};
  background: ${purpleTelus};
  position: sticky;
  top: 0;
  z-index: 1035;
`;

const TopbarContainer = styled.div`
  height: 3.5rem;
  display: flex;
  align-items: center;
  width: 100%;
  padding-left: 0;
  padding-right: 0;

  @media ${devices.mobileM} {
    justify-content: space-between;
  }

  @media ${devices.tablet} {
    padding-left: ${padds.LeftRightTablet};
    padding-right: ${padds.LeftRightTablet};
  }

  @media ${devices.laptop} {
    padding-left: ${padds.LeftRight};
    padding-right: ${padds.LeftRight};
  }
`;

const TopbarLeftSide = styled.div`
  display: flex;
  flex: 1;
  @media ${devices.mobileM} {
    align-items: center;
  }
`;

const TopbarRightSide = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export declare interface TopbarProps {
  customers?: CustomerInfo[];
  adminMenu?: MenuItem[];
  supportMenu?: MenuItem[];
  languageMenu?: MenuItem[];
  ngBroadcast?: (name: string, ...args: unknown[]) => void;
}

export const Topbar: React.FC<TopbarProps> = ({
  customers,
  adminMenu,
  supportMenu,
  languageMenu,
  ngBroadcast,
}) => {
  const { t, i18n } = useTranslation();

  const { session, setSession } = useContext(SessionContext);

  const handleSelectCustomer = (customerId?: number) => {
    if (ngBroadcast) {
      ngBroadcast("sherlock:select-customer", customerId);
    }
  };

  const changeLanguage = (language: string) => (event: Event) => {
    event.preventDefault();

    void i18n.changeLanguage(language);
  };

  const logout = (event: Event) => {
    event.preventDefault();

    setSession(null);
  };

  const profileMenu = [
    { label: t("Preferences"), link: "#/user/profile" } as MenuItem,
  ]
    .concat(
      (supportMenu ?? []).map((item) => {
        return { label: item.label, link: item.link };
      })
    )
    .concat(
      (languageMenu ?? []).map((item) => {
        return { label: item.label, action: changeLanguage(item.link) };
      })
    )
    .concat([{ label: t("Logout"), action: logout }]);

  return (
    <TopbarStyle>
      <TopbarContainer>
        <TopbarLeftSide>
          {adminMenu && (
            <DropdownMenu
              id="top-admin-menu-dropdown"
              theme="Admin"
              label={t("Administration")}
              menuItems={adminMenu}
            />
          )}
          {customers && (
            <Autocomplete
              copy={i18n.language}
              placeholder={t("Enter a customer name")}
              noResults={t("No customers found")}
              maxSuggestions={20}
              initialItems={customers.map((customer) => {
                return { id: customer.id, label: customer.name };
              })}
              onClear={() => handleSelectCustomer(null)}
              onSelect={handleSelectCustomer}
            />
          )}
        </TopbarLeftSide>
        <TopbarRightSide>
          <StackView space={3} direction="row">
            {supportMenu && (
              <Responsive min="md">
                <React.Fragment>
                  {supportMenu.map((item) => (
                    <Link
                      variant={{ inverse: true }}
                      href={item.link}
                      target="_blank"
                    >
                      {item.label}
                    </Link>
                  ))}
                </React.Fragment>
              </Responsive>
            )}
            {languageMenu && (
              <Responsive min="md">
                <React.Fragment>
                  {languageMenu.map((item) => (
                    <Link
                      variant={{ inverse: true }}
                      href={"#"}
                      onClick={changeLanguage(item.link)}
                    >
                      {item.label}
                    </Link>
                  ))}
                </React.Fragment>
              </Responsive>
            )}
            {session && (
              <NavBarNotificationWidget
                notifications={session?.notifications}
              />
            )}
          </StackView>
          {session && (
            <DropdownMenu
              id="top-user-preferences-dropdown"
              alignRight={true}
              theme="User"
              label={session?.user?.displayName}
              menuItems={profileMenu}
            />
          )}
        </TopbarRightSide>
      </TopbarContainer>
    </TopbarStyle>
  );
};
