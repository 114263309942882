import React, { useEffect, useState } from "react";
import styled from "styled-components";

import {
  colorTelusPurple,
  colorGreyShark,
  colorGreyAthens,
} from "@tds/core-colours";
import {
  CaretDown,
  Profile,
  Settings,
  Download,
} from "@tds/core-interactive-icon";
import { ComputerNetwork } from "@tds/core-decorative-icon";
import Responsive from "@tds/core-responsive";

import { padds } from "../../../style/global-style";
import { MenuItem } from "../../../datamodel/navigation";

const DropdownMenuStyle = styled.div<{ alignRight: boolean; isOpen: boolean }>`
  height: 100%;
  a {
    text-decoration: none;
    font-size: 0.9rem;
  }

  ul {
    list-style: none;
    margin: 0;
    padding-left: 0;
  }

  li {
    color: #fff;
    background: ${colorTelusPurple};
    display: block;
    float: left;
    padding: ${padds.p16};

    position: relative;
    text-decoration: none;
    transition-duration: 0.2s;
  }

  li a {
    color: #fff;
    display: flex;
    justify-content: space-between;
    white-space: nowrap;
  }

  li:hover a {
    color: ${colorTelusPurple};
  }

  li:hover,
  li:focus-within {
    background: ${(props) => (props.isOpen ? `white` : `${colorTelusPurple}`)};
    cursor: pointer;
  }

  li:focus-within a {
    outline: none;
    color: ${colorTelusPurple};
  }

  ul li ul {
    visibility: ${(props) => (props.isOpen ? `visible` : `hidden`)};
    opacity: ${(props) => (props.isOpen ? `1` : `0`)};
    position: absolute;
    transition: all 0.1s ease;
    margin-top: 1rem;
    left: 0;
    display: ${(props) => (props.isOpen ? "block" : "none")};
  }

  ul li:hover > ul,
  ul li:focus-within > ul,
  ul li ul:hover,
  ul li ul:focus {
    color: ${colorTelusPurple};
  }

  ul li ul li {
    clear: both;
    width: 100%;
  }

  .hidden {
    display: none;
  }

  svg {
    margin-right: 0.2rem;
    max-height: 1.3rem;
    &:hover {
      transition: none;
      -webkit-transform: none;
    }
  }

  .user-label {
    max-width: 25ex;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
const CaretDownStyle = styled(CaretDown)`
  margin-right: -0.4rem !important;
`;

const DropDownStyle = styled.ul<{ alignRight: boolean }>`
  min-width: ${(props) => (props.alignRight ? "100%" : "200px")};
  background-color: white;
  border-width: 0px 1px 1px;
  border-right-style: solid;
  border-bottom-style: solid;
  border-left-style: solid;
  border-right-color: rgb(216, 216, 216);
  border-bottom-color: rgb(216, 216, 216);
  border-left-color: rgb(216, 216, 216);
  border-image: initial;
  border-radius: 0px 0px 4px 4px;
  border-top-style: initial;
  border-top-color: initial;
  box-shadow: rgb(213 213 213 / 50%) 10px 10px 16px -8px,
    rgb(213 213 213 / 50%) -10px 10px 16px -8px;
  z-index: 1600;
  text-align: left;
  list-style-type: none;

  li {
    background-color: white;
    color: ${colorGreyShark};
  }
  li a {
    display: flex;
    justify-content: ${(props) =>
      props.alignRight ? "flex-end" : "flex-start"};
    color: ${colorGreyShark};
    white-space: nowrap;
  }
  li:hover {
    background-color: ${colorGreyAthens};
  }
`;

export declare interface DropdownMenuProps {
  id?: string;
  label?: string;
  menuItems: MenuItem[];
  theme?: string;
  alignRight?: boolean;
}

export const DropdownMenu: React.FC<DropdownMenuProps> = (props) => {
  /*state for dropdown*/
  const [isOpen, setIsOpen] = useState(false);
  const [isMenuItemClicked, setIsMenuItemClicked] = useState(false);
  const [asyncClose, setAsyncClose] = useState(false);

  /*Close the menu when link is clicked*/
  const menuClicked =
    (item: MenuItem) => (event: React.BaseSyntheticEvent<Event>) => {
      setIsMenuItemClicked(true);
      if (item.action) {
        item.action(event.nativeEvent);
      }
      setIsOpen(false);
    };
  /*take the menu content from props & generate a list*/
  const menuItems = props.menuItems;
  const menuList = menuItems.map((item) => (
    <a href={item.link} target={item.link?.match(/^https?:/) ? "_blank" : null}>
      <li onClick={menuClicked(item)} key={item.label}>
        {item.label}
      </li>
    </a>
  ));

  useEffect(() => {
    if (asyncClose && isMenuItemClicked) {
      setIsOpen(!asyncClose);
      setAsyncClose(false);
      setIsMenuItemClicked(false);
    }
  }, [asyncClose, isMenuItemClicked]);

  return (
    <DropdownMenuStyle alignRight={props.alignRight} isOpen={isOpen}>
      <nav role="navigation">
        <ul>
          <li
            onMouseEnter={() => setIsOpen(true)}
            onMouseLeave={() => setIsOpen(false)}
            /*for mobile*/
            onTouchStart={() =>
              !isOpen ? setIsOpen(true) : setAsyncClose(true)
            }
          >
            <a id={props.id} aria-haspopup="true">
              {/*Icons*/}
              {props.theme == "Customers" ? (
                <ComputerNetwork variant={isOpen ? "default" : "inverted"} />
              ) : null}
              {props.theme == "Admin" ? (
                <Settings color={isOpen ? "telusPurple" : "white"} />
              ) : null}
              {props.theme == "User" ? (
                <Profile color={isOpen ? "telusPurple" : "white"} />
              ) : null}
              {props.theme == "Download" ? (
                <Download color={isOpen ? "telusPurple" : "white"} />
              ) : null}

              {/*Label*/}
              {props.theme == "User" ? (
                <span className="user-label">
                  {" "}
                  {props.label ? props.label : "Dropdown menu"}{" "}
                </span>
              ) : (
                <Responsive
                  minWidth="md"
                  render={() => <span> {props.label} </span>}
                />
              )}

              <CaretDownStyle variant={isOpen ? "alternative" : "inverted"} />
            </a>

            {/*Dropdown content*/}
            <DropDownStyle alignRight={props.alignRight} aria-label="submenu">
              {menuList}
            </DropDownStyle>
          </li>
        </ul>
      </nav>
    </DropdownMenuStyle>
  );
};
