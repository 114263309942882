import { react2angular } from "react2angular";

export default function asComponent(
  Class: React.ComponentType<unknown>,
  extraBindingNames: Record<string, string> = {}
): angular.IComponentOptions {
  const component = react2angular(Class);

  component.bindings["tabindex"] = "@";
  component.bindings["id"] = "@childId";

  Object.keys(extraBindingNames).forEach(function (key) {
    component.bindings[key] = extraBindingNames[key];
  });

  return component;
}
