import React, { PropsWithChildren, useCallback } from "react";
import {
  BoldExtension,
  BulletListExtension,
  HardBreakExtension,
  HeadingExtension,
  ImageExtension,
  ItalicExtension,
  LinkExtension,
  OrderedListExtension,
  PlaceholderExtension,
  TableExtension,
  UnderlineExtension,
} from "remirror/extensions";
import {
  EditorComponent,
  Remirror,
  TableComponents,
  ThemeProvider,
  useRemirror,
  RemirrorProps,
  OnChangeHTML,
} from "@remirror/react";
import { AllStyledComponent } from "@remirror/styles/styled-components";
import { TopToolbar } from "./TopToolbar/TopToolbar";

import { CreateEditorStateProps } from "remirror";
import FloatingLinkToolbar from "./FloatingLinkToolbar/FloatingLinkToolbar";

export interface RichTextEditorProps
  extends Pick<CreateEditorStateProps, "stringHandler">,
    Pick<RemirrorProps, "initialContent" | "editable" | "autoFocus" | "hooks"> {
  placeholder?: string;
  onChange?: (html: string) => void;
}

export const RichTextEditor: React.FC<
  PropsWithChildren<RichTextEditorProps>
> = ({
  placeholder,
  stringHandler = "html",
  children,
  initialContent,
  onChange,
  ...rest
}) => {
  const extensions = useCallback(
    () => [
      new BoldExtension({}),
      new BulletListExtension({}),
      new HardBreakExtension(),
      new HeadingExtension({}),
      new ImageExtension(),
      new ItalicExtension(),
      new LinkExtension({ autoLink: true }),
      new OrderedListExtension(),
      new PlaceholderExtension({ placeholder }),
      new TableExtension(),
      new UnderlineExtension(),
    ],
    [placeholder]
  );

  const { manager } = useRemirror({ extensions, stringHandler });

  const handleEditorChange = useCallback((html: string) => onChange(html), []);

  return (
    <AllStyledComponent>
      <ThemeProvider>
        <Remirror manager={manager} initialContent={initialContent} {...rest}>
          <OnChangeHTML onChange={handleEditorChange} />
          <TopToolbar />
          <EditorComponent />
          <TableComponents />
          <FloatingLinkToolbar />
          {children}
        </Remirror>
      </ThemeProvider>
    </AllStyledComponent>
  );
};
