import { IconButton } from "@telus-uds/components-base";
import {
  Badge,
  Box,
  Card,
  Link,
  StackView,
  Typography,
} from "@telus-uds/components-web";
import { NotifyBold } from "@telus-uds/palette-allium/build/web/icons";
import React, { ElementType } from "react";
import { useTranslation } from "react-i18next";
import Notifications from "../../notifications/Notifications";
import { Session } from "./../../../datamodel/session";

export declare interface NavBarNotificationWidgetProps {
  notifications?: Session["notifications"];
}

export const NavBarNotificationWidget: React.FC<
  NavBarNotificationWidgetProps
> = ({ notifications }) => {
  const { t, i18n } = useTranslation();
  const hasUnreadNotification = (notifications?.size ?? 0) > 0;

  const getNotificationContent = () => {
    return (
      <Card
        variant={{ padding: "custom" }}
        tokens={{
          paddingBottom: 12,
          paddingLeft: 18,
          paddingRight: 18,
          paddingTop: 12,
        }}
      >
        <Typography block variant={{ size: "h3" }}>
          {t("New notification")}
        </Typography>
        <Box top={3}>
          <Typography>
            {notifications.highlight[i18n.language as "en" | "fr"]}
          </Typography>
        </Box>
        <Box top={3}>
          <Link
            icon={NotifyBold as ElementType}
            href="#/user/notifications"
            accessibilityLabel={t("Notifications")}
          >
            {t("Go to notifications")}
          </Link>
        </Box>
      </Card>
    );
  };

  return (
    <>
      <StackView direction="row">
        <Box>
          <IconButton
            icon={NotifyBold as ElementType}
            href="#/user/notifications"
            accessibilityLabel={t("Notifications")}
            variant={{ inverse: true }}
          />
        </Box>
        {hasUnreadNotification && <Badge>{notifications?.size}</Badge>}
      </StackView>
      {hasUnreadNotification && (
        <Notifications
          id="notifications"
          type="info"
          content={getNotificationContent()}
        />
      )}
    </>
  );
};
