import * as React from "react";
import { useTranslation } from "react-i18next";

import { ByteFormat } from "../formatters/byteformat/ByteFormat";
import { DateFormat } from "../formatters/dateformat/DateFormat";

import DocumentInfo from "./DocumentInfo";
import { isDocumentViewable } from "./utils";

export declare interface DocumentPopoverContentProps {
  document: DocumentInfo;
}

export const DocumentPopoverContent: React.FC<DocumentPopoverContentProps> = ({
  document,
}) => {
  const { t } = useTranslation();

  return (
    <div className="popover-detail">
      <div className="popover-detail-item">
        <strong>{t("Name") + t(":")}</strong> {document.name}
      </div>
      <div className="popover-detail-item">
        <strong>{t("Creation date") + t(":")}</strong>{" "}
        <DateFormat value={document.created} format="medium" />
      </div>
      <div className="popover-detail-item">
        <strong>{t("Modification date") + t(":")}</strong>{" "}
        <DateFormat value={document.modified} format="medium" />
      </div>
      <div className="popover-detail-item">
        <strong>{t("Size") + t(":")}</strong>{" "}
        <ByteFormat value={document.size} />
      </div>

      {isDocumentViewable(document) && (
        <div className="tile-button-bar">
          <a className="tile-button" title={t("Consult")} href={document.url}>
            <i className="fa fa-eye tile-button-icon center-block show"></i>
            {t("Consult", { context: "action" })}
          </a>

          <a
            className="tile-button"
            title={t("Download", { context: "action" })}
            href={document.url + "?forceDownload"}
          >
            <img
              src="./images/ico_download_144px.png"
              className="icon tile-button-icon center-block show"
              alt=""
            />
            {t("Download", { context: "action" })}
          </a>
        </div>
      )}
    </div>
  );
};
