
import * as React from "react";

import Checkbox from "@tds/core-checkbox";
import DecorativeIcon from "@tds/core-decorative-icon";
import A11yContent from "@tds/core-a11y-content";

export declare interface SrOnlyLabeledCheckboxProps {
  label: string;
  name: string;
  value: string | number | boolean;
  error?: string;
  feedback?: "error";
  id?: string;
  disabled?: boolean;
  checkmark?: boolean;
  checkmarkTitle?: string;
  checkmarkTabIndex?: number;
  checkmarkInverted?: boolean;
}

export const SrOnlyLabeledCheckbox: React.FC<SrOnlyLabeledCheckboxProps> = ({
  checkmark,
  checkmarkTitle,
  checkmarkTabIndex,
  checkmarkInverted,
  label,
  ...rest }) => (
  <span>
    {checkmark ? (
      <span title={checkmarkTitle} tabIndex={checkmarkTabIndex}>
        <DecorativeIcon
          symbol="checkmark"
          variant="primary"
          size={16}
        />
      </span>
    ) : checkmarkInverted ? (
      <span title={checkmarkTitle} tabIndex={checkmarkTabIndex}>
        <DecorativeIcon
          symbol="checkmark"
          variant="inverted"
          size={16}
        />
      </span>
    ) : (
      <Checkbox label={<A11yContent>{label}</A11yContent>}{...rest} />
    )}
  </span>
);
