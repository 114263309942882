import * as React from "react";

import { ComponentInfo } from "../../../datamodel/statuspage";

import Notification from "@tds/core-notification";

export declare interface StatusPageComponentWidgetProps {
  componentStatus: ComponentInfo;
}

export const StatusPageComponentWidget: React.FC<StatusPageComponentWidgetProps> =
  ({ componentStatus }) => {
    let variant = "";
    if (componentStatus.status == "operational") {
      variant = "success";
    } else if (componentStatus.status == "major_outage") {
      variant = "error";
    } else {
      variant = "warning";
    }
    return (
      <Notification variant={variant} copy="en">
        {componentStatus.name}
      </Notification>
    );
  };
