import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import resources from "./i18n-resources";

void i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: window.localStorage.getItem("NG_TRANSLATE_LANG_KEY"),
    fallbackLng: "en",

    // allow keys to be phrases having `:`, `.`
    nsSeparator: false,
    keySeparator: false,

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
