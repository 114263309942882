import * as React from "react";

import moment from "moment";

export declare type DateFormatFormat = "medium" | "short";

export declare interface DateFormatProps {
  id?: string;
  value: moment.MomentInput;
  format?: DateFormatFormat;
}

const MOMENT_FORMATS: Record<DateFormatFormat, string> = {
  medium: "ll HH:mm:ss",
  short: "L LT",
};

export const DateFormat: React.FC<DateFormatProps> = ({
  id,
  value,
  format,
}) => {
  return (
    <span id={id}>
      {moment(value).format(
        format ? MOMENT_FORMATS[format] : moment.defaultFormat
      )}
    </span>
  );
};
