// https://github.com/remirror/remirror/blob/main/packages/remirror__react-editors/src/components/top-toolbar.tsx

import React from "react";

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import {
  Toolbar,
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  HeadingLevelButtonGroup,
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  BasicFormattingButtonGroup,
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  ListButtonGroup,
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  CreateTableButton,
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  DataTransferButtonGroup,
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  HistoryButtonGroup,
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  VerticalDivider,
} from "@remirror/react";

export const TopToolbar: React.FC = () => {
  return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <Toolbar>
      <HeadingLevelButtonGroup showAll={true} />
      <VerticalDivider />
      <BasicFormattingButtonGroup />
      <VerticalDivider />
      <ListButtonGroup />
      <CreateTableButton />
      <VerticalDivider />
      <DataTransferButtonGroup />
      <VerticalDivider />
      <HistoryButtonGroup />
    </Toolbar>
  );
};
