import {
  // colorTelusPurple,
  colorTelusGreen,
  // colorAccessibleGreen,
  colorWhite,
  colorGreyShark,
  // colorGreyShuttle,
  colorGreyRaven,
  colorGreyGainsboro,
  colorGreyAthens,
  // colorLavenderBlush,
  // colorPanache,
  // colorWhiteLilac,
  // colorCardinal,
  // colorRajahDark,
  // colorRajah,
  // colorRajahLight,
} from "@tds/core-colours";

const sherlockTheme = {
  font: {
    base: {
      family: '"TELUS-Web", "Helvetica Neue", Helvetica, Arial, sans-serif',
      size: 16,
    },
    small: {
      size: 12,
    },
    note: {
      size: 12,
    },
  },
  colors: {
    colorTelusGreen,
    labelColor: colorWhite,
    tdsColorText: colorGreyShark,
    colorTextInverted: colorWhite,
    colorTableBorder: colorGreyRaven,
    colorTableHeaderBackground: colorGreyGainsboro,
    colorTableEvenRow: colorGreyAthens,
    colorTableExpandedRow: colorGreyRaven,
    colorTableExpandedRowContent: colorWhite,
  },
};

export default sherlockTheme;
