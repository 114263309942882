/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import * as React from "react";

import { ComponentInfo } from "../../../datamodel/oneloginstatus";

import Progress from "@tds/community-progress";
import Box from "@tds/core-box";
import Link from "@tds/core-link";
import { LinkExternal } from "@tds/core-interactive-icon";
import { useTranslation } from "react-i18next";

export declare interface OneLoginStatusWidgetProps {
  componentStatus: ComponentInfo;
}

export const OneLoginStatusWidget: React.FC<OneLoginStatusWidgetProps> = ({
  componentStatus: { status = -1 },
}) => {
  const { t } = useTranslation();
  const URL = "https://www.onelogin.com/status#eu";
  return status !== -1 ? (
    <Box inset={3} between={2}>
      <Progress>
        <Progress.Bar
          percentage={100}
          variant="negative"
          a11yLabel="OneLogin negative status"
          aria-label="OneLogin negative status"
          title={100 - status}
        />
        <Progress.Bar
          percentage={status}
          variant="positive"
          a11yLabel="OneLogin positive status"
          aria-label="OneLogin positive status"
          title={status}
        />
      </Progress>
      <Link href={URL} icon={LinkExternal} iconPosition="right" target="_blank">
        {URL}
      </Link>
    </Box>
  ) : (
    <Box inset={3} between={2}>
      <Progress>
        <Progress.Bar
          percentage={100}
          variant="disabled"
          a11yLabel="OneLogin unavailable"
          aria-label="OneLogin unavailable"
          title={t("UNREACHABLE")}
        />
      </Progress>
      <Link href={URL} icon={LinkExternal} iconPosition="right" target="_blank">
        {URL}
      </Link>
    </Box>
  );
};
