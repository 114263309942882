import React, { Fragment, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Slide, toast, ToastContainer, ToastContent } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import styled from "styled-components";

import {
  colorAccessibleGreen,
  colorLavenderBlush,
  colorPanache,
  colorRajahDark,
  colorRajahLight,
  colorTelusPurple,
  colorWhiteLilac,
} from "@tds/core-colours";
import { Information } from "@tds/core-decorative-icon";
import {
  NotificationError,
  NotificationSuccess,
  NotificationWarning,
} from "@tds/core-feedback-icon";

const NotificationsStyle = styled(ToastContainer)`
  & {
    top:4rem !important;
  }

   .Toastify__toast-body * {
    font-weight:bold;font-family: "TELUS-Web", "Helvetica Neue", Helvetica, Arial, sans-serif !important;
  }

  .Toastify__toast-icon{
    margin-right:1.2rem;
  }

  /* Error */
  .Toastify__toast--error {
    background: ${colorLavenderBlush} !important;

  }

  /* Warning */
  .Toastify__toast--warning {
     background: ${colorRajahLight} !important;
  }
  .Toastify__toast-icon--warning {
    color: ${colorRajahDark};
  }

  /* Success */
  .Toastify__toast--success {
     background: ${colorPanache} !important;
  }
  .Toastify__toast-icon--success {
    color: ${colorAccessibleGreen};
  }
  /* Info */
  .Toastify__toast--info {
     color:${colorTelusPurple}
    background: ${colorWhiteLilac} !important;
    button[aria-label="close"] {
      color: ${colorTelusPurple};
      opacity: 0.75;
      &:hover {
        opacity: 1;
      }
    }
  }
  .Toastify__toast-icon--info {
    color: ${colorTelusPurple};
  }
  .Toastify__progress-bar--info {
    background: ${colorTelusPurple} !important;
  }
`;

interface NotificationsProps {
  id: string;
  type: "error" | "info" | "warning" | "success";
  content: ToastContent<unknown>;
}

const autoClose = 5000;

const Notifications: React.FC<NotificationsProps> = ({ id, type, content }) => {
  const { t } = useTranslation();

  const show = () => {
    switch (type) {
      case "error":
        toast.error(content, {
          icon: <NotificationError copy={{ a11yText: t("Error") }} />,
          autoClose: false,
          hideProgressBar: true,
        });
        break;
      case "info":
        toast.info(content, {
          icon: <Information />,
          autoClose: autoClose,
          hideProgressBar: false,
          progress: undefined,
        });
        break;
      case "success":
        toast.success(content, {
          icon: <NotificationSuccess copy={{ a11yText: t("Success") }} />,
          autoClose: autoClose,
          hideProgressBar: false,
          progress: undefined,
        });
        break;
      case "warning":
        toast.warning(content, {
          icon: <NotificationWarning copy={{ a11yText: t("Warning") }} />,
          autoClose: false,
          hideProgressBar: true,
        });
        break;
      default:
    }
  };

  useEffect(() => {
    show();
  }, [id]);

  return (
    <Fragment key={id}>
      <NotificationsStyle
        transition={Slide}
        newestOnTop={true}
        closeOnClick
        draggable={false}
      />
    </Fragment>
  );
};

export default Notifications;
