import * as React from "react";
import { useTranslation } from "react-i18next";

export declare interface ByteFormatProps {
  value?: number;
}

const UNITS = ["Bytes", "kiBytes", "MiBytes", "GiBytes", "TiBytes", "PiBytes"];

export const ByteFormat: React.FC<ByteFormatProps> = ({ value }) => {
  const { t } = useTranslation();

  if (value === null || isNaN(value) || !isFinite(value)) {
    return <span>-</span>;
  } else {
    const magnitude =
      value === 0.0 ? 0 : Math.floor(Math.log(value) / Math.log(1024));
    const truncated = (value / Math.pow(1024, magnitude)).toFixed(0);

    return (
      <span>{t(UNITS[magnitude], { context: "unit", value: truncated })}</span>
    );
  }
};
