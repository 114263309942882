import React from "react";
import Card from "@tds/core-card";
import Box from "@tds/core-box";
import Text from "@tds/core-text";
import FlexGrid from "@tds/core-flex-grid";
import { Information } from "@tds/core-decorative-icon";
import { useTranslation } from "react-i18next";
import { DateFormat } from "../../formatters/dateformat/DateFormat";

export declare interface MdrGeneralCaseInfoCardProps {
  isTelusUser: boolean;
  individualCase: {
    id: number;
    caseSource: string;
    serviceComponent: string;
    createdAt: string;
    updatedAt: string;
  };
}

export const MdrGeneralCaseInfoCard: React.FC<MdrGeneralCaseInfoCardProps> = ({
  isTelusUser,
  individualCase,
}) => {
  const { t } = useTranslation();
  const { id, caseSource, serviceComponent, createdAt, updatedAt } =
    individualCase;

  const translatedCaseSource =
    caseSource == "CORTEX_XDR"
      ? "Cortex XDR"
      : caseSource == "MANUAL"
      ? t("Manual")
      : caseSource;

  return (
    <FlexGrid>
      <FlexGrid.Row>
        <FlexGrid.Col>
          <Card variant="grey">
            <Box between={3}>
              <Box inline between={2}>
                <Box>
                  <Information variant="alternative" />{" "}
                </Box>
                <Box>
                  <Text>{t("General information")}</Text>
                </Box>
              </Box>
              <Box>
                <Text>
                  {t("Case Id:")} {id}
                </Text>
              </Box>
              <Box>
                <Box>
                  <Text>
                    {t("Case Source:")} {translatedCaseSource}
                  </Text>
                </Box>
                {isTelusUser && (
                  <Box>
                    <Text>
                      {t("Service Component:")} {t(serviceComponent)}
                    </Text>
                  </Box>
                )}
              </Box>
              <Box>
                <Box>
                  <Text>
                    {t("Time:")}{" "}
                    <DateFormat value={createdAt} format="medium" />{" "}
                  </Text>
                </Box>
                <Box>
                  <Text>
                    {t("Last Modified:")}{" "}
                    <DateFormat value={updatedAt} format="medium" />{" "}
                  </Text>
                </Box>
              </Box>
            </Box>
          </Card>
        </FlexGrid.Col>
      </FlexGrid.Row>
    </FlexGrid>
  );
};
